import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://linkedin.com/in/grant-mcnatt" target='noopener'><BsLinkedin /></a>
        <a href="https://www.instagram.com/grant.mcnatt" target='noopener'><BsInstagram /></a>
        <a href="https://twitter.com/ZoOtMcNoOt" target='noopener'><BsTwitterX /></a>
    </div>
  )
}

export default HeaderSocials