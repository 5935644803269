import React from 'react'
import './about.css'
import ME from '../../assets/camp-square.jpg'
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoFolderOpenOutline } from "react-icons/io5";

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt="about__image"/>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>5+ Years Proficiently Coding</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Leadership</h5>
              <small>Three Leadership Positions</small>
            </article>

            <article className='about__card'>
              <IoFolderOpenOutline className='about__icon'/>
              <h5>Projects</h5>
              <small>Completed Projects Soon!</small>
            </article>
          </div>
          
          <p>
          I am currently a second-year Biomedical Engineering major at Texas A&M University who hopes to further my career in the technology field by targeting problems with data-driven solutions. After volunteering over the past semester at St. Joseph's Regional Health Center, I have shifted my focus from a sole interest in practicing medicine to finding applicable real-world solutions.

This year, I have mastered Microsoft Office, Solidworks, and introductory MATLAB and have begun learning Python, C++, JavaScript, and R. This summer, I intend to understand circuit design and machining-friendly design to prototype devices and systems throughout my college career. 

I'm more than just a student, however! In my free time, you might find me running, hiking, backpacking through the middle of nowhere, learning guitar, reading about history, watching jazz concerts, or playing tabletop games with my friends!
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About