import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/fled.jpg'
import AVTR2 from '../../assets/sail.jpg'
import AVTR3 from '../../assets/fish.jpg'
import AVTR4 from '../../assets/chi.jpg'
import IMG1 from '../../assets/cleanup.JPEG'
import IMG2 from '../../assets/cima.JPEG'
import IMG3 from '../../assets/fleggo.JPG'
import IMG4 from '../../assets/chistjoe.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { EffectCards , Pagination , Navigation , Zoom} from 'swiper/modules';

const data = [
  
  {
    avatar: AVTR2,
    photo: IMG1,
    name: 'Sophomores Advancing In Leadership',
    review: 'As a dedicated member of the Service Committee and the Finance and Philanthropy Subcommittee within Sophomores Advancing in Leadership, my involvement has been multifaceted. In the Service Committee, I actively participated in organizing impactful charity and service events, ranging from street cleanups to Boys and Girls Club initiatives, leaving a positive imprint on the local community. Simultaneously, as part of the Finance and Philanthropy Subcommittee, my primary role involves collaborating with local businesses to secure sponsorships for SAIL\'s flagship philanthropy event, Dodge For A Cause. This annual dodgeball tournament not only serves as a dynamic fundraising initiative for the local community but also significantly contributes to our outreach for The BEE Community, our chosen philanthropy for the year. The BEE Community purpose is to create employment opportunities for individuals with developmental and intellectual disabilities. Through strategic partnerships with local businesses, we not only secure vital funds for our philanthropic mission but also foster community engagement and support for The BEE Community, reinforcing SAIL\'s commitment to leadership, service, and positive community impact.'
  },
  {
    avatar: AVTR3,
    photo: IMG2,
    name: 'Texas A&M Fish Camp',
    review: 'As a Texas A&M Fish Camp Counselor, I played a multifaceted role in supporting incoming freshmen as they transitioned to university life. This position involved ensuring the safety, development, and well-being of campers by meticulously following state and university protocols. I also dedicated myself to cultivating relationships with both campers and fellow counselors through active listening and dynamic interpersonal skills, fostering a welcoming and inclusive camp environment. Additionally, I served as a mentor for incoming freshmen, providing invaluable guidance and support as they pursued their academic and career goals. This holistic approach aimed to create a memorable and enriching experience for all campers, setting them on a path to academic success and personal growth.'
  },
  {
    avatar: AVTR4,
    photo: IMG4,
    name: 'St. Josephs Emergency Room Volunteer',
    review: 'As an Emergency Room Volunteer at St. Joseph Health Regional Hospital, I worked six hours per week, performing basic triage, ensuring patient safety, and assisting the medical team. I helped categorize patient cases based on their severity, contributed to a comforting atmosphere, and supported the nursing staff by handling paperwork, equipment, and patient transport. I also maintain cleanliness and organization in the ER, following safety protocols. This role has deepened my understanding of healthcare operations and my commitment to service.'
  },
  {
    avatar: AVTR1,
    photo: IMG3,
    name: 'Freshman Leaders In Education And Human Development',
    review: 'As a Freshman Leaders in Education and Human Development member, I\'ve contributed by serving as a Mentor and Development Committee Member. As a development committee member, I managed resource development and fundraising initiatives, collaborated with third-party organizations to support the Vision House charity and other philanthropies like Aggie Habitat for Humanity, and coordinated profit-share events with local businesses.  I volunteered at South Knoll Elementary as part of the FLED initiative, teaching math to underprivileged elementary school students and coordinating events in partnership with the university to raise awareness about poverty and the importance of early childhood education. I also extended my commitment this following year as a mentor for the new members of FLED. In this role, I offer resources to the Class of 2027, ensuring a smooth transition of responsibilities for the organization\'s ongoing success.'
  }
]
const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>How Do I Show</h5>
      <h2>Involvement</h2>

      <Swiper  className='container testimonials__container' effect={'cards'}
        grabCursor={true}
        modules={[Pagination, EffectCards, Navigation, Zoom]}
        pagination={{clickable: true}}
        navigation= {true}
        zoom={true}
        >
        {
          data.map(({avatar, photo, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__avatar'>
                  <img src={avatar} alt='Avatar' />
                </div>
                  <img  className='client__photo'src={photo} alt="" />
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{review}</small>
              </SwiperSlide>
            )
          })
      }
      </Swiper>
      
    </section>
  )
}

export default Testimonials