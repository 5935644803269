import React from 'react'
import './education.css'
import { FaCheck } from "react-icons/fa6"

const Education = () => {
  return (
    <section id='education'>
      <h5>About My</h5>
      <h2>Education</h2>
      <div className='container'>
      
      <div className="container college__container">
        <h3>Texas A&M University</h3>
        <h4 className='text-dark'>Bachelor of Science in Biomedical Engineering</h4>
        <small className='text-light'>August 2022 - Present</small>
        <div className='college__relevant-coursework'>
          <h3>Relevant Coursework</h3>
          <ul className='service__list'>
              <li>
                <FaCheck className='service__list-icon'/>
                <p>Computation for Engineering</p>
              </li>
              <li>
                <FaCheck className='service__list-icon'/>
                <p>Physiology for Bioengineers 1 and 2</p>
              </li>
              <li>
                <FaCheck className='service__list-icon'/>
                <p>Organic Chemistry 1 and 2</p>
              </li>
              <li>
                <FaCheck className='service__list-icon'/>
                <p>Engineering Math 1, 2, and 3</p>
              </li>
              <li>
                <FaCheck className='service__list-icon'/>
                <p>Newtonian Mechanics</p>
              </li>
              <li>
                <FaCheck className='service__list-icon'/>
                <p>Electricity and Magnetism</p>
              </li>
            </ul>
            
        </div>
        <div className='cta'>
          <a className='btn btn-primary' href="#contact">Request Transcript</a>
            </div>
        
      </div>
      </div>
      

    </section>
  )
}

export default Education