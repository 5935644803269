import React, { useRef } from 'react'
import './contact.css'
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import emailjs from '@emailjs/browser';


export const Contact = () => {
  const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      
      emailjs.sendForm("service_18y8b6p","template_y5am1ad", form.current, 'nWXKcfMMBWoF9SlUG')
      
};

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>gmcnatt1@gmail.com</h5>
            <a href="mailto:gmcnatt1@gmail.com">Send a message</a>
          </article>

          <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>grant.mcnatt</h5>
            <a href="https://m.me/grant.mcnatt.37">Send a message</a>          
          </article>

          <article className='contact__option'>
            <FiPhoneCall className='contact__option-icon'/>
            <h4>Phone</h4>
            <h5>+14698857796</h5>
            <a href="tel:4698857796">Send a message</a>   
          </article>
        </div>
        {/*End Of Contact Options */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required/>
          <input type="email" name="email"  placeholder='Your Email' required/>
          <input type="text"  name="subject"placeholder='Your Subject' required/>
          <textarea type="text" name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}
export default Contact