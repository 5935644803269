import React from 'react'
import './footer.css'
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";

const Footer = () => {
  return (
    <footer>
      <a href="#bottom" className='footer__logo'>Grant McNatt</a>

      <ul className='permalinks'>
        <li><a href="#top">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#education">Education</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Involvement</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href="https://linkedin.com/in/grant-mcnatt" target='noopener'><BsLinkedin /></a>
        <a href="https://www.instagram.com/grant.mcnatt" target='noopener'><BsInstagram /></a>
        <a href="https://twitter.com/ZoOtMcNoOt" target='noopener'><BsTwitterX /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Grant McNatt. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer